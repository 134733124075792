/* eslint-disable no-alert */
/* eslint-disable radix */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect, useRef, useCallback, useMemo} from 'react'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  Select,
  Typography,
  MenuItem,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import styled from 'styled-components'
import {decode as base64Decode} from 'base-64'
import {useDispatch, useSelector} from 'react-redux'
import {CustomButton, CustomTextBox, DataGrid, Loader} from '../../../components'
import {tableConfig} from '../../../utils/Table'
import {ColumnConfig} from '../../../utils/Column'
import {getProjectDataAction} from '../../../redux/reports/ReportActions'
import {getAssetListDataAction} from '../../../redux/assets/AssetsActions'
import {adminListActions} from '../../../redux/admin/admin'
import {assetsListActions} from '../../../redux/assets/assets'
import {audioData} from '../../../constants/constant'
import MyDiv from './assetList.style'


const StyledMenuItem = styled(MenuItem)(() => ({
  '&.MuiMenuItem-root': {
    borderBottom: '1px solid #ccc',
    color: '#000000',
    fontWeight: '400',
    fontSize: '14px',
    fontFamily: 'Arial',
  },
  '&.MuiMenuItem-root:first-child': {
    marginTop: '-8px',
  },
  '&.MuiMenuItem-root:last-child': {
    marginBottom: '-8px',
  },
}))

const ITEM_HEIGHT = 60
const Menu = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5,
    },
  },
}


export default function AssetList(props) {

  const dispatch = useDispatch()
  const [config, setConfig] = useState(tableConfig)
  const [columnConfig, setColumnConfig] = useState(ColumnConfig)
  const [columnSetting, setColumnSetting] = useState(false)
  const [inputValues, setInputValues] = useState({})
  const [assetListData, setAssetListData] = useState([])
  const [locationId, setLocationId] = useState(null)
  const [show, setShow] = useState(true)
  const [filterAssetInDataGrid, setFilterAssetInDataGrid] = useState(false)
  const [isGlobalSearch, setIsGlobalSearch] = useState(false)
  const [ref, setRef] = useState('')
  const [asset_id, setAsset_id] = useState('')
  const [assetCount, setAssetCount] = useState('')

  const {report: reportState, assets: assetsState, admin: adminState} = useSelector((state) => state)


  let projectID = localStorage.getItem('project_id')
  let locationIDHome = localStorage.getItem('location_id_home')
  let selectedArea = localStorage.getItem('selectedArea')
  let selectedAppraiser = localStorage.getItem('selectedAppraiser')
  // let locationIDHome = null
  let projectIDCrm = localStorage.getItem('project_id_crm')
  let decodedProjectId = base64Decode(projectIDCrm) // This is a Base64 encoded string


  let projectByIdData = {
    project_id_crm: decodedProjectId,
  }

  useEffect(() => {
    const resetSearch = () => {
      if (filterAssetInDataGrid)setFilterAssetInDataGrid(false)
    }
    setTimeout(() => {
      resetSearch()
    }, 2000)
  }, [filterAssetInDataGrid])
  const getProjectData = useCallback(() => {
    if (projectByIdData.project_id_crm && reportState?.projectDataList?.projectDataList?.length === 0) {
      dispatch(getProjectDataAction(projectByIdData))
    }
  }, [dispatch, projectByIdData.project_id_crm, reportState?.projectDataList?.projectDataList])

  const getAssetListData = useCallback(() => {
    let locationData = {
      action: 'getLocation',
      project_id: Number(projectID),
    }
    let appraiserData = {
      action: 'getAppraiser',
      project_id: Number(projectID),
    }
    let areaData = {
      action: 'getArea',
      project_id: Number(projectID),
    }

    dispatch(getAssetListDataAction(locationData))
    dispatch(getAssetListDataAction(appraiserData))
    dispatch(getAssetListDataAction(areaData))
  }, [dispatch, projectID])

  useEffect(() => {
    getAssetListData()
  }, [projectID])

  useEffect(() => {
    getProjectData()
  }, [getProjectData])

  useEffect(() => {
    setAssetListData(assetsState?.getAssetListData)
  }, [assetsState?.getAssetListData])
  // const locationChangeCount = useRef(0)

  const handleChange = (e, type) => {
    const {name, value} = e.target
    if (name === 'area') {
      if (value) {
        localStorage.setItem('selectedArea', value)
      } else {
        localStorage.removeItem('selectedArea')
      }
    }
    if (name === 'appraiser') {
      if (value > 0) {
        localStorage.setItem('selectedAppraiser', value)
      } else {
        localStorage.removeItem('selectedAppraiser')
      }
    }
    if (name !== 'isAudio') setAsset_id('')
    if (type === 'locations') {
      dispatch(assetsListActions.setDoFetchAssetList(true))
      setLocationId(value)
      if (value > 0 && locationIDHome) { // ! some new case where we no longer want to remove the location if it is being set directly in asset list and not from home page
        // localStorage.setItem('manually_updated_location', 1)
      }
      if (value > 0) {
        localStorage.setItem('manually_updated_location', 1)
        localStorage.setItem('location_id_home', value)
        localStorage.removeItem('lastAssetAddLocation')
      } else {
        localStorage.removeItem('manually_updated_location')
        localStorage.removeItem('location_id_home')
      }
      setInputValues({location: value})
    } else {
      setInputValues({
        ...inputValues,
        [name]: value,
      })
      if (name === 'area' || name === 'appraiser' || name === 'isAudio') dispatch(assetsListActions.setDoFetchAssetList(true))
    }
  }

  useEffect(() => {
    let selectedAppraiser = localStorage.getItem('selectedAppraiser')
    let selectedArea = localStorage.getItem('selectedArea')
    let locationIDHome = localStorage.getItem('location_id_home')
    let globalSearchString = localStorage.getItem('global_search_string')
    let location
    let appraiser
    let area
    let search = ''
    if (locationIDHome) {
      location = locationIDHome
    } else if (inputValues?.location) {
      location = inputValues?.location
    } else {
      location = 0
    }
    if (selectedAppraiser) {
      appraiser = +selectedAppraiser
    } else if (inputValues?.appraiser) {
      appraiser = inputValues?.appraiser
    } else {
      appraiser = 0
    }
    if (selectedArea) {
      area = +selectedArea
    } else if (inputValues?.area) {
      area = inputValues?.area
    } else {
      area = 0
    }
    if (globalSearchString && globalSearchString !== 'undefined') {
      search = globalSearchString
    }
    // const location = locationIDHome ?? inputValues?.location ?? 0
    // const appraiser = selectedAppraiser ?? inputValues?.appraiser ?? 0
    // const area = selectedArea ?? inputValues?.area ?? 0
    // localStorage.setItem('updatedLocationId', location?.toString())
    setLocationId(location)
    setInputValues({location, area, appraiser, search})
    // setInputValues({location, area, appraiser})
  }, [])

  useEffect(() => {
    const hasDefinedValue = Object.values(inputValues).some((value) => value !== undefined && value !== null)
    if (!hasDefinedValue) return setShow(true)
    if ((inputValues.area && inputValues.area !== 0) || (inputValues.appraiser && inputValues.appraiser !== 0) || inputValues.hide_assets || inputValues.show_assets || inputValues.delete) {
      setShow(false)
    } else {
      setShow(true)
    }
  }, [inputValues])

  const handleSidebar = () => {
    setColumnSetting(!columnSetting)
  }
  const handleSelect = () => {
    setShow(!show)
  }

  const handleReset = () => {
    const manuallyUpdated = localStorage.getItem('manually_updated_location')

    // manuallyUpdated == ? localStorage.removeItem('location_id_home') : null
    localStorage.removeItem('selectedArea')
    localStorage.removeItem('selectedAppraiser')
    localStorage.removeItem('global_search_string')
    dispatch(assetsListActions.setDoFetchAssetList(true))
    setRef('')
    setAsset_id('')
    // eslint-disable-next-line eqeqeq
    if (manuallyUpdated == 1) {
      localStorage.removeItem('location_id_home')
      localStorage.removeItem('manually_updated_location')
      setInputValues({
        location: '',
        appraiser: '',
        area: '',
        isAudio: '',
        search: '',
        hide_assets: false,
        delete: false,
        show_assets: false,
      })
    } else {
      const location = localStorage.getItem('location_id_home')
      setInputValues({
        location,
        appraiser: '',
        area: '',
        isAudio: '',
        search: '',
        hide_assets: false,
        delete: false,
        show_assets: false,
      })
    }
    window.location.reload()

    // handleSearch()
  }

  let timeoutId
  const handleCheckbox = (e) => {
    e.stopPropagation()
    const {name, checked} = e.target
    setInputValues({...inputValues, [name]: checked})
  }


  const handleSearch = () => {
    if (asset_id || ref) { // ! if we have a value in ref or asset_id field, we do a local search rather than a global(api call) search
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        setRef('')
        setAsset_id('')
      }, 2000)
      setFilterAssetInDataGrid(true)
    } else {
      setAsset_id('')
      setRef('')
      localStorage.setItem('global_search_string', inputValues?.search)
      setInputValues({...inputValues, ref: '', asset_id: ''})
      setIsGlobalSearch(true)
      dispatch(assetsListActions.setDoFetchAssetList(true))
    }
  }
  const handleSearchOnEnter = (e) => {
    if (e.keyCode === 13) {
      setAsset_id('')
      setRef('')
      localStorage.setItem('global_search_string', inputValues?.search)
      setInputValues({...inputValues, ref: '', asset_id: ''})
      setIsGlobalSearch(true)
      dispatch(assetsListActions.setDoFetchAssetList(true))
    }
  }
  const updateParentGlobalSearch = (bool) => {
    setAsset_id('')
    setRef('')
    setInputValues({...inputValues, ref: '', asset_id: ''})
    setIsGlobalSearch(bool)
  }

  const handleTextBox = (e, type) => {
    if (type === 'ref') {
      clearTimeout()
      setRef(e.target.value)
      // setSearchID(e.target.value)
      setAsset_id('')
      setInputValues({...inputValues, search: ''})
      // scrollToTop()
    }
    if (type === 'asset_id') {
      clearTimeout()
      setAsset_id(e.target.value)
      // setSearchID(e.target.value)
      setRef('')
      setInputValues({...inputValues, search: ''})
      // scrollToTop()
    }
    if (type === 'search') {
      setAsset_id('')
      // setSearchID('')
      setRef('')
      // setSearchText(e.target.value)
      setInputValues({...inputValues, search: e.target.value})
    }
  }

  const handleAssetCount = (count) => {
    setAssetCount(count)
  }
  const uniqueUsernames = new Set()
  const uniqueAppraiserData = []

  assetListData?.getAppraiserData?.forEach((entry) => {
    if (!uniqueUsernames.has(entry.username)) {
      uniqueUsernames.add(entry.username)
      uniqueAppraiserData.push(entry)
    }
  })

  const uniqueCombination = new Set()
  const uniqueAreaData = []

  assetListData?.getAreaData?.forEach((entry) => {
    const combination = entry.area
    if (!uniqueCombination.has(combination)) {
      uniqueCombination.add(combination)
      uniqueAreaData.push(entry)
    }
  })


  const handleFindAsset = (e) => {

    if (e.keyCode === 13) {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        setRef('')
        setAsset_id('')
      }, 2000)

      setFilterAssetInDataGrid(true)
    }
  }

  const AppraisedAsPerLocation = locationId === 0 ? uniqueAppraiserData : assetListData?.getAppraiserData?.filter(({location_id}) => +locationId === +location_id)
  return (
    <MyDiv>
      {(reportState?.projectDataList?.loading || assetsState?.getAssetListData?.loading) &&
      <div>
        <Loader />
      </div>
      }
      <Box className="page_heading">
        <Box className="heading_box">
          <Typography>
            Assets List <span>Total Assets : {assetCount}</span>
          </Typography>
          <FormControl className="checkbox_container">
            <FormControlLabel
              className="checked_label"
              control={<Checkbox value={inputValues.hide_assets} onChange={(e) => {
                handleCheckbox(e)
                dispatch(assetsListActions.setDoFetchAssetList(true))
              }
              } checked={inputValues.hide_assets ?? false} name="hide_assets"
              />}
              label="Hide Cat. Assets"
            />
            <FormControlLabel
              className="checked_label"
              control={<Checkbox value={inputValues.show_assets} onChange={(e) => {
                handleCheckbox(e)
                dispatch(assetsListActions.setDoFetchAssetList(true))
              }
              } checked={inputValues.show_assets ?? false} name="show_assets"
              />}
              label="Show Assets"
            />
            <FormControlLabel
              className="checked_label"
              control={<Checkbox value={inputValues.delete} onChange={(e) => {
                handleCheckbox(e)
                dispatch(assetsListActions.setDoFetchAssetList(true))
              }
              } checked={inputValues.delete ?? false} name="delete"
              />}
              label="NF / Deleted"
            />
          </FormControl>
        </Box>
        <Box className="form_box">
          <FormControl size="small" fullWidth>
            <Select
              id="select_input"
              className="select_value"
              value={inputValues.location || locationId || 0} // need to cleanup this as well
              onChange={(e) => handleChange(e, 'locations')}
              name="location"
              MenuProps={{...Menu}}
            >
              <StyledMenuItem value={0}>All Locations</StyledMenuItem>
              {assetListData?.getLocationtData?.map((item, index) => {
                return (
                  <StyledMenuItem key={index} value={item.location_id}>
                    {item.name} [{item.street} {item.city} {item.country}]
                  </StyledMenuItem>
                )
              })}
            </Select>
          </FormControl>
          <FormControl size="small" fullWidth>
            <Select
              id="select_input"
              className="select_value"
              value={inputValues.appraiser || 0}
              onChange={handleChange}
              name="appraiser"
              MenuProps={{...Menu}}
            >
              <StyledMenuItem value={0}>All Appraisers</StyledMenuItem>

              {
                AppraisedAsPerLocation?.map((item, index) => {

                  return (
                    <StyledMenuItem key={index} value={item.user_id}>
                      {item.last_name} {item.name}
                    </StyledMenuItem>
                  )
                })
              }
            </Select>
          </FormControl>
          <FormControl size="small" fullWidth>
            <Select
              id="select_input"
              className="select_value"
              value={inputValues.area || 0}
              onChange={handleChange}
              name="area"
              MenuProps={{...Menu}}
            >
              <StyledMenuItem value={0}>All Areas</StyledMenuItem>
              {locationId !== 0 ? assetListData?.getAreaData?.filter((data) => +data.location_id === +locationId)?.map((item, index) => {
                return (
                  <StyledMenuItem key={index} value={item.area}>
                    {item.area}
                  </StyledMenuItem>
                )
              }) : uniqueAreaData?.map((item, index) => {
                return (
                  <StyledMenuItem key={index} value={item.area}>
                    {item.area}
                  </StyledMenuItem>
                )
              })
              }
            </Select>
          </FormControl>
          <CustomTextBox fieldLabel="Search" name="search" onKeyDown={handleSearchOnEnter} value={inputValues?.search || ''} onChange={(e) => handleTextBox(e, 'search')} className="search_input" />
          <FormControl size="small" fullWidth>
            <Select
              id="select_input"
              className="select_value"
              value={inputValues.isAudio || 0}
              onChange={handleChange}
              name="isAudio"
              MenuProps={{...Menu}}
            >
              <StyledMenuItem value={0}>Select</StyledMenuItem>
              {audioData?.map((item, index) => {
                return (
                  <StyledMenuItem key={index} value={item.id}>
                    {item.label}
                  </StyledMenuItem>
                )
              })}
            </Select>
          </FormControl>
          {show ? <><CustomTextBox fieldLabel="Ref#" value={ref} onKeyDown={handleFindAsset} onChange={(e) => handleTextBox(e, 'ref')} className="custom_input" />
            <CustomTextBox fieldLabel="Asset Id" value={asset_id} onKeyDown={handleFindAsset} onChange={(e) => handleTextBox(e, 'asset_id')} className="custom_input" /></> : null}

          <Box className="button_group">
            <CustomButton
              title="Search"
              variant="contained"
              className="btn_theme"
              onClick={handleSearch}
            />
            <CustomButton
              title="Reset"
              variant="contained"
              className="btn_reset"
              onClick={handleReset}
            />
          </Box>
        </Box>
      </Box>
      <IconButton onClick={handleSidebar} className="column_setting">
        <MenuIcon />
      </IconButton>
      {/* {getDataGrid} */}
      <DataGrid
        config={config}
        locationId={assetListData?.getLocationtData}
        setConfig={setConfig}
        columnConfig={columnConfig}
        setColumnConfig={setColumnConfig}
        columnSetting={columnSetting}
        handleSidebar={handleSidebar}
        showIcon={show}
        handleSelect={handleSelect}
        inputdata={inputValues}
        updateParentGlobalSearch={updateParentGlobalSearch}
        isGlobalSearch={isGlobalSearch}
        refs={ref}
        asset_ids={asset_id}
        appraiserData={assetListData?.getAppraiserData}
        handleAssetCount={handleAssetCount}
        filterAssetInDataGrid={filterAssetInDataGrid}
      />
    </MyDiv>
  )
}
