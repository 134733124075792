/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable template-curly-spacing */
import React, {useState, useEffect, useRef} from 'react'
import {
  Box,
  Grid,
  Typography,
  MenuItem,
  FormControl,
  Select,
  IconButton,
  Tooltip,
} from '@mui/material'
import {Add} from '@mui/icons-material'
import styled from 'styled-components'
import {useDispatch, useSelector} from 'react-redux'
import {decode as base64Decode} from 'base-64'
import {Typeahead} from 'react-bootstrap-typeahead'
import {useHistory} from 'react-router-dom'
import * as routesNames from '../../../constants/routes'
import {
  AlertMessage,
  CustomButton,
  CustomTextArea,
  CustomTextBox,
  Loader,
} from '../../../components'
import AddAssetDialog from '../../AddAssetDialog'
import {getLocationsDataAction} from '../../../redux/home/HomeActions'
import {getAssetDetailsAction, addAssetTypeAction, getMakeCapacityAction, postLineDetailAction} from '../../../redux/lines/LineActions'
import {lineListActions} from '../../../redux/lines/line'
import {fetchAssetDetails} from '../../../services/lineServices'
import MyDiv from './addLine.style'

const StyledMenuItem = styled(MenuItem)(() => ({
  '&.MuiMenuItem-root': {
    borderBottom: '1px solid #ccc',
    color: '#000000',
    fontWeight: '400',
    fontSize: '14px',
    fontFamily: 'Arial',
  },
  '&.MuiMenuItem-root:first-child': {
    marginTop: '-8px',
  },
  '&.MuiMenuItem-root:last-child': {
    marginBottom: '-8px',
  },
}))

const ITEM_HEIGHT = 60
const Menu = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5,
    },
  },
}

const condition = [
  {
    id: 1,
    name: 'New',
    value: 'N',
  },
  {
    id: 2,
    name: 'Excellent',
    value: 'E',
  },
  {
    id: 3,
    name: 'Very Good',
    value: 'VG',
  },
  {
    id: 4,
    name: 'Good',
    value: 'G',
  },
  {
    id: 5,
    name: 'Fair',
    value: 'F',
  },
  {
    id: 6,
    name: 'Poor',
    value: 'P',
  },
  {
    id: 7,
    name: 'NA',
    value: 'NA',
  },
  {
    id: 8,
    name: 'Scrap',
    value: 'X',
  },
  {
    id: 9,
    name: 'Salvage',
    value: 'S',
  },
]

export default function AddLine() {

  const dispatch = useDispatch()
  const history = useHistory()
  const [inputValues, setInputValues] = useState({})
  console.log('🚀 ~ AddLine ~ inputValues:', inputValues)
  const [lineValues, setLineValues] = useState({})
  const [openDialog, setOpenDialog] = useState(false)
  const [locationData, setLocationData] = useState()
  const [assetData, setAssetData] = useState([])
  const [locationId, setLocationId] = useState()
  const [fetchAssetDetailsRequest, setFetchAssetDetailsRequest] = useState(false)
  const [addValue, setAddValue] = useState('')
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const homeState = useSelector((state) => state.home)
  const lineState = useSelector((state) => state.line)
  const [valueCode, setValueCode] = useState([])
  const [value, setValue] = useState([])
  const [isDataChanged, setDataChanged] = useState(true)
  const locationsDataFull = locationData?.locationsDataFull
  const appraiserList = assetData?.appraiserList
  let userId = localStorage.getItem('userId')
  let projectIDCrm = localStorage.getItem('project_id_crm')
  let decodedProjectId = base64Decode(projectIDCrm) // This is a Base64 encoded string

  const locationsData = {
    project_id_crm: decodedProjectId,
    type: 'locationData',
  }

  let makeCapacityData = {
    type: 'getMakeCapacityList',
    project_id_crm: decodedProjectId,
  }

  const assetHashRef = useRef()
  const makeRef = useRef()
  const assetTypeRef = useRef()

  useEffect(() => {
    assetHashRef.current.focus()
  }, [])
  useEffect(() => {
  //? add new line always on last location an asset or line was added at
    const homeLocation = localStorage.getItem('location_id_home')
    let lastActiveLocation = localStorage.getItem('lastAssetAddLocation')
    let activeLocation = 0
    if (lastActiveLocation && lastActiveLocation !== 'undefined') {
      activeLocation = lastActiveLocation
    } else if (homeLocation) {
      activeLocation = homeLocation
    } else {
      activeLocation = locationsDataFull?.[0]?.location_id?.toString()
    }
    let assetData = {
      location_id: activeLocation,
      type: 'line',
      project_id_crm: decodedProjectId,
    }
    dispatch(getAssetDetailsAction(assetData))
    setInputValues({location_id: activeLocation})
    setLocationId(activeLocation)
  }, [locationsDataFull])


  useEffect(() => {
    dispatch(getLocationsDataAction(locationsData))
    dispatch(getMakeCapacityAction(makeCapacityData))
  }, [dispatch])

  useEffect(() => {
    setLocationData(homeState?.locationsList?.locationsList?.data)
  }, [homeState?.locationsList?.locationsList])

  useEffect(() => {
    if (appraiserList && isDataChanged) {
      setInputValues({...inputValues, asset_class_id: 1, appraiser: appraiserList?.[0]?.user_id, condition: 'Good', line_text2: ', Consisting of:'})
    }
  }, [appraiserList])


  // useEffect(() => {
  //   // let location = localStorage.getItem('location_id_home') ?? locationsDataFull?.[0]?.location_id?.toString()
  //   let assetData = {
  //     location_id: locationId?.toString(),
  //     type: 'line',
  //     project_id_crm: decodedProjectId,
  //   }
  //   console.log('asset data', assetData)
  //   if (locationId !== 'undefined') {
  //     dispatch(getAssetDetailsAction(assetData))
  //   }
  // }, [fetchAssetDetailsRequest])

  useEffect(() => {
    let assetDetailData = {
      type: 'line',
      project_id_crm: decodedProjectId,
    }
    if (!fetchAssetDetailsRequest) {
      fetchAssetDetails(assetDetailData).then((res) => {
        // setAssetDetails(res)
        const valueCodeRes = res?.data?.locationPriceValues
          .reduce(
            (acc, curr) => [...acc, curr.value_short_name],
            []
          )
        setValueCode(valueCodeRes)
        setFetchAssetDetailsRequest(true)
      })
    }
  }, [fetchAssetDetailsRequest])

  useEffect(() => {
    setAssetData(lineState?.assetDetails?.assetDetails?.data)
  }, [lineState?.assetDetails?.assetDetails])

  useEffect(() => {
    const showMessage = () => {
      if (lineState?.messageData.status === 'success') {
        setMessage(lineState?.messageData?.message)
        setStatus('success')
        setAddValue('')
        setOpenDialog(false)
      }
      if (lineState?.messageData.status === 'failed') {
        setMessage(lineState?.messageData?.message)
        setStatus('warning')
      }
    }

    const timer = setTimeout(() => {
      showMessage()
    }, 2000)

    return () => {
      clearTimeout(timer)
    }
  }, [lineState?.messageData])

  useEffect(() => {
    return () => {
      dispatch(lineListActions.clearMessageData())
    }
  }, [])

  const makeList = lineState?.makeCapacityList?.makeCapacityList?.data?.makeList
  const assetTypes = assetData?.assetTypeList
  const filteredAssetTypes = assetTypes?.length > 0 && assetTypes[0]?.asset_type === 'Select or search asset type' ? assetTypes?.slice(1) : assetTypes
  const handleChange = (e) => {
    let value = e.target.value
    if (e.target.name === 'line_text2') {
      if (value.length < 100) {
        setInputValues({...inputValues, line_text2: e.target.value})
      }
    } else if (e.target.name === 'line_name') {
      if (value.length < 500) {
        setInputValues({...inputValues, line_name: e.target.value})
      }
    } else {
      setInputValues({...inputValues, [e.target.name]: e.target.value})
    }
  }

  const handleChangeValues = (e, type) => {
    if (type === 'location') {
      setLocationId(e.target.value)
      setInputValues({location_id: e.target.value})
      setLineValues({})
      setDataChanged(true)
      assetTypeRef.current.clear()
      makeRef.current.clear()
      // dispatch(getLocationsDataAction(locationsData))
      // dispatch(getMakeCapacityAction(makeCapacityData))
      let assetData = {
        location_id: e.target.value?.toString(),
        type: 'line',
        project_id_crm: decodedProjectId,
      }
      dispatch(getAssetDetailsAction(assetData))
      // setFetchAssetDetailsRequest(false)
      // localStorage.setItem('locationIdforLine', e.target.value)
      // window.location.reload()
      // let assetData = {
      //   location_id: e.target.value.toString(),
      //   type: 'line',
      //   project_id_crm: decodedProjectId,
      // }
      // dispatch(getAssetDetailsAction(assetData))
    } else {
      setLineValues({...lineValues, [e.target.name]: e.target.value})
      setInputValues({...inputValues, [e.target.name]: e.target.value})
    }
  }

  const handleChangeAdd = (e) => {
    let value = e.target.value
    if (value?.length <= 40) {
      setAddValue(e.target.value)
    }
  }
  const handleDialog = () => {
    setOpenDialog(!openDialog)
    setAddValue('')
  }
  const handleAdd = () => {
    if (!addValue) {
      setMessage('Please Enter Asset Type')
      setStatus('warning')
      return
    }
    let addObj = {
      created_by: Number(userId),
      modified_by: Number(userId),
      type: addValue,
      location_id: locationId,
      project_id_crm: decodedProjectId,
    }
    const isAlreadyExists = filteredAssetTypes?.find(({asset_type}) => asset_type === addValue)
    if (isAlreadyExists) {
      setMessage('Asset Type Already Exists')
      setStatus('warning')
    } else {
      dispatch(addAssetTypeAction(addObj))
      setDataChanged(false)
      handleDialog()
    }
  }


  const handleSelected = (selected, type) => {
    let label
    if (selected.length === 0) return
    // we set lable in case we are adding a custom value and not one that is already in the list
    if (selected[0]?.label) label = true
    switch (type) {
      // if we have a label, we set the values accordingly based on a ternary check
      case 'make':
        setInputValues({...inputValues, selectedMake: selected, make: label ? selected[0]?.label : selected[0]?.make, make_id: label ? null : selected[0]?.make_id})
        break
      case 'assetType':
        setInputValues({...inputValues, selectedAssetType: selected, asset_type: label ? selected[0]?.label : selected[0]?.asset_type, asset_type_id: label ? null : selected[0]?.asset_type_id, asset_class_id: +selected[0]?.class_id ?? 0})
        break
      default:
        break
    }
  }
  useEffect(() => {
    let priceVal = []
    const finalValue = assetData?.locationPriceValues
    finalValue?.forEach((item) => {
      for (const key in inputValues) {
        if (item.value_short_name === key) {
          let obj = {
            name: item.value_code,
            value: inputValues[key],
          }
          priceVal.push(obj)
        }
      }
    })
    setValue(priceVal)
  }, [inputValues])

  const transformedPriceVal = value?.reduce((result, item) => {
    result[item.name] = item.value
    return result
  }, {})

  const handleSubmit = () => {
    localStorage.setItem('lastAssetAddLocation', locationId) // add the last location in all cases
    if (inputValues?.make || inputValues?.line_name || inputValues?.asset_type) {
      let Payload = {
        appraiser: inputValues?.appraiser,
        condition: inputValues?.condition,
        asset_class_id: inputValues?.asset_class_id,
        asset_no: inputValues?.asset_no,
        asset_type_id: inputValues?.asset_type_id,
        cost: inputValues?.cost,
        make: inputValues?.make,
        created_by: Number(userId),
        formAction: 'saveUpdate',
        location_id: locationId,
        line_name: inputValues?.line_name,
        line_text2: inputValues?.line_text2,
        make_id: inputValues?.make_id,
        modified_by: Number(userId),
        priceVal: transformedPriceVal,
        project_id: locationsDataFull?.[0]?.project_id,
        type: 'line',
        year: inputValues?.year,
        appraiser_note: inputValues?.appraiser_note,
      }
      dispatch(postLineDetailAction(Payload, 'addLine'))
      history.push('/asset-list')

      // history.push('/asset-list')
    } else {
      setMessage('Please enter atleast one field from Make, Text1 and Asset Type.')
      setStatus('warning')
    }
  }
  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
    dispatch(lineListActions.clearMessageData())
  }
  const handleCancel = () => {
    history.push('/asset-list')

  }

  const handleFocus = () => {
    let updatedInputValues = {...inputValues}
    const allowedValues = ['no value', 'leased', 'not valued']

    valueCode.forEach((val) => {
      if (allowedValues.includes(updatedInputValues[val]?.toLowerCase()?.trim()) || +updatedInputValues[val] > 0) {
        // Valid value, do nothing
      } else {
        updatedInputValues[val] = '' // Reset invalid value
      }
    })

    setInputValues(updatedInputValues)
  }
  const CheckType = (value) => {
    let flag
    let digitRegex = /^[0-9]+$/
    let valid = digitRegex.test(value)
    if (valid) {
      flag = true
    } else {
      flag = false
    }
    return flag
  }
  // useEffect(() => {
  //   setInputValues({...inputValues, appraiser: 29})
  //   setInputValues({...inputValues, condition: 'Good'})
  //   setInputValues({...inputValues, line_text2: ', Consisting of:'})
  //   setInputValues({...inputValues, asset_class_id: 1})
  // }, [])


  const validateCost = (item) => {
    // ! A total of 6 cases are being handled as per the requirements
    switch (item) {
      case 'FLV':
        if (+inputValues.FLV > +inputValues.OLV || +inputValues.FLV > +inputValues.FMV) return true
        break
      case 'OLV':
        if (+inputValues.OLV > +inputValues.FMV || +inputValues.OLV > +inputValues.OLVIP) return true
        break
      case 'FMV':
        if (+inputValues.FMV > +inputValues.FMVIP) return true
        break
      case 'OLVIP':
        if (+inputValues.OLVIP > +inputValues.FMVIP) return true
        break
      default:
        break
    }
  }

  const handleSelectedForLineAndAssetType = (selected) => {
    let label
    if (selected[0]?.label) label = true
    setInputValues({
      ...inputValues,
      selectedAssetType: selected,
      asset_type: label ? selected[0]?.label : selected[0]?.asset_type,
      asset_type_id: label ? null : selected[0]?.asset_type_id,
      asset_class_id: +selected[0]?.class_id ? +selected[0]?.class_id : 0,
    })
  }
  const handleKeyDownForTypeAhead = (e) => {
    const {placeholder, value} = e.target
    if (!value) return
    if (e.keyCode === 9) {
      if (placeholder === 'Search Make') {
        const makeId = makeList?.find(({make}) => make === value)?.make_id
        setInputValues({...inputValues, selectedMake: [{make: value}], make: value, make_id: makeId})
      }
      if (placeholder === 'Search Asset Types') {
        const assetTypeValue = filteredAssetTypes?.find(({asset_type}) => asset_type === value)
        setInputValues({...inputValues, selectedAssetType: [{asset_type: value}], asset_type: value, asset_type_id: assetTypeValue?.asset_type_id, asset_class_id: assetTypeValue?.class_id})
      }
    }
  }

  const handleCustomTypeAheadForMake = (makeValue) => {
    if (Array.isArray(makeValue)) return
    setInputValues({...inputValues, selectedMake: [{make: makeValue}], make: makeValue})
  }

  return (
    <MyDiv>
      {(homeState?.locationsList?.loading || lineState?.assetDetails?.loading || lineState?.postLineDetail?.loading || lineState?.assetType?.loading) &&
        <div>
          <Loader />
        </div>
      }
      <Box className="page_heading">
        <Typography>Add Line</Typography>
      </Box>
      <Box className="wrapper_box">
        <Grid container columnSpacing={{sm: 4, md: 4}}>
          <Grid item md={3} className="project_name d-flex">
            <Typography variant="h6">Project :-</Typography>
            <Typography>{locationsDataFull?.[0]?.project_name}</Typography>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography>Select Location</Typography>
              <FormControl size="small" fullWidth>
                <Select
                  id="select_input"
                  className="select_value"
                  value={lineValues.location || locationId !== undefined ? locationId : null}
                  onChange={(e) => handleChangeValues(e, 'location')}
                  name="location"
                  MenuProps={{...Menu}}
                >
                  {locationsDataFull?.map((item, index) => {
                    return (
                      <StyledMenuItem key={index} value={item.location_id}>
                        {item.name} [{item.street} {item.city} {item.country}]
                      </StyledMenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography>Appraiser</Typography>
              <FormControl size="small" fullWidth>
                <Select
                  id="select_input"
                  className="select_value"
                  value={inputValues?.appraiser ?? ''}
                  onChange={handleChange}
                  name="appraiser"
                  MenuProps={{...Menu}}
                >
                  {appraiserList?.map((item, index) => {
                    return (
                      <StyledMenuItem key={index} value={item.user_id}>
                        {item.last_name}{' '}{item.name}
                      </StyledMenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography>Asset #</Typography>
              <input type="text" ref={assetHashRef} value={inputValues?.asset_no ?? ''}
                onChange={handleChange}
                name="asset_no"
                style={{height: 35,
                  width: '100%'}}
              />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography>Make</Typography>
              <Typeahead
                id="basic-typeahead"
                allowNew
                // clearButton
                ref={makeRef}
                filterBy={(option, props) => {
                  return option?.make?.toLowerCase().startsWith(props?.text.toLowerCase())
                }}
                labelKey={(option) => option?.make?.toString() || ''}
                options={makeList}
                placeholder="Search Make"
                selected={inputValues?.selectedMake} // we are expecting it in the format [{key:key_value, value: value}]
                size="large"
                onChange={(selected) => handleSelected(selected, 'make')}
                onInputChange={handleCustomTypeAheadForMake}
                onKeyDown={handleKeyDownForTypeAhead}
              />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Box className="text_flex">
                <Typography>Text 1</Typography>
                <Typography className="note">Max 500 characters</Typography>
              </Box>
              <CustomTextBox
                value={inputValues?.line_name ?? ''}
                onChange={handleChange}
                name="line_name"
                maxLength="500"
              />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Box className="text_flex">
                <Typography>Text 2</Typography>
                <Typography className="note">Max 100 characters</Typography>
              </Box>
              <CustomTextBox
                value={inputValues?.line_text2 ?? ''}
                onChange={handleChange}
                name="line_text2"
                maxLength="100"
              />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography>Year</Typography>
              <CustomTextBox
                maxLength={4}
                value={inputValues?.year ?? ''}
                onChange={handleChange}
                name="year"
              />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Box className="d-flex add_btn">
                <Typography>Asset Type</Typography>
                <Tooltip tabIndex={-1} title="Add New Asset Type">
                  <IconButton onClick={handleDialog}><Add /></IconButton></Tooltip>
              </Box>
              <Typeahead
                id="basic-typeahead"
                ref={assetTypeRef}
                // allowNew
                filterBy={(option, props) => {
                  return option?.asset_type?.toLowerCase().startsWith(props?.text.toLowerCase())
                }}
                // clearButton
                labelKey={(option) => option?.asset_type?.toString() || ''}
                options={filteredAssetTypes}
                placeholder="Search Asset Types"
                selected={inputValues?.selectedAssetType} // we are expecting it in the format [{key:key_value, value: value}]
                size="large"
                onChange={(selected) => handleSelectedForLineAndAssetType(selected)}
                onKeyDown={handleKeyDownForTypeAhead}
              />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography>Asset Class</Typography>
              <FormControl size="small" fullWidth>
                <Select
                  id="select_input"
                  className="select_value"
                  value={inputValues.asset_class_id ?? 1}
                  onChange={handleChange}
                  name="asset_class_id"
                  MenuProps={{...Menu}}
                >
                  {assetData?.assetClassList?.map((item, index) => {
                    return (
                      <StyledMenuItem key={index} value={item.asset_class_id}>
                        {item.name}
                      </StyledMenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography>Condition</Typography>
              <FormControl size="small" fullWidth>
                <Select
                  id="select_input"
                  className="select_value"
                  value={inputValues.condition ?? ''}
                  onChange={handleChange}
                  name="condition"
                  MenuProps={{...Menu}}
                >
                  {condition?.map((item, index) => {
                    return (
                      <StyledMenuItem key={index} value={item.name}>
                        {item.name}
                      </StyledMenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography>Cost ($)</Typography>
              <CustomTextBox
                value={inputValues?.cost ?? ''}
                onChange={handleChange}
                name="cost"
              />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography>Appraiser Notes</Typography>
              <CustomTextArea minRows={3} value={inputValues.appraiser_note ?? ''} name="appraiser_note" onChange={handleChange} />
            </Box>
          </Grid>
          {valueCode?.map((value, index) => {
            let percentageValue = ((inputValues[value]) / inputValues?.cost) * 100
            let type = CheckType(inputValues[value])
            return (
              <Grid item md={3} key={index}>
                <Box className="input_box">
                  <Typography>{value} ($):</Typography>
                  {type ? (inputValues[value] && inputValues?.cost > 0 ? <Typography>{percentageValue.toFixed(2)}% of Cost</Typography> : '') : ''}

                  <CustomTextBox
                    className={validateCost(value) ? 'coral_background' : null}
                    onChange={handleChangeValues}
                    name={value}
                    value={inputValues[value] ?? ''}
                    onBlur={handleFocus}
                  />
                </Box>
              </Grid>
            )
          })}
        </Grid>
        <Box className="d-flex btn-flex mb-20 mt-10">
          <CustomButton
            title="Save & Back"
            variant="contained"
            className="btn_theme"
            onClick={handleSubmit}
          />
          <CustomButton
            title="Cancel"
            variant="contained"
            className="btn_theme"
            onClick={handleCancel}
          />
        </Box>
      </Box>
      <AddAssetDialog handleDialog={handleDialog} openDialog={openDialog} addValue={addValue} handleChangeAdd={handleChangeAdd} handleAdd={handleAdd} />
      <AlertMessage
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
    </MyDiv>
  )
}
