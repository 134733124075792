/* eslint-disable guard-for-in */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {useEffect, useState} from 'react'
import {
  Box,
  FormControl,
  Select,
  Typography,
  MenuItem,
  Grid,
} from '@mui/material'
import styled from 'styled-components'
import {useDispatch} from 'react-redux'
import {decode as base64Decode} from 'base-64'
import {useHistory, useParams} from 'react-router-dom'
import {AlertMessage, CustomButton, CustomTextBox, Loader} from '../../../components'
import {AssetIdList, ImagesList} from '../components'
// import {getMakeCapacityAction} from '../../../redux/lines/LineActions'
// import {
//   getLocationsDataAction,
// } from '../../../redux/home/HomeActions'
import {fetchMakeCapacityList} from '../../../services/lineServices'
import {fetchLocations} from '../../../services/homeService'
import {fetchAssetPhotos, fetchAssetsByAppraiser, fetchPhotosByAppraiser, postPhotosToAssets, deletePhotosToAssets} from '../../../services/assetListService'
// import {getAssetsByAppraiserAction} from '../../../redux/assets/AssetsActions'
import {assetsListActions} from '../../../redux/assets/assets'
// import {fetchLocationList} from '../../../services/adminService'
import MyDiv from './mapPhotos.style'

const StyledMenuItem = styled(MenuItem)(() => ({
  '&.MuiMenuItem-root': {
    borderBottom: '1px solid #ccc',
    color: '#000000',
    fontWeight: '400',
    fontSize: '14px',
    fontFamily: 'Arial',
  },
  '&.MuiMenuItem-root:first-child': {
    marginTop: '-8px',
  },
  '&.MuiMenuItem-root:last-child': {
    marginBottom: '-8px',
  },
}))

const ITEM_HEIGHT = 60
const Menu = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5,
    },
  },
}

export default function MapPhotos(props) {
  const {id} = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const [appraisersList, setAppraisersList] = useState([])
  // const [filterAppraisersList, setFilterAppraisersList] = useState([])
  const [inputValues, setInputValues] = useState({})
  const [locationData, setLocationData] = useState([])
  const [assetsData, setAssetsData] = useState()
  const [photosAssetData, setPhotosAssetData] = useState([])
  const [imagesList, setImagesList] = useState([])
  const [expanded, setExpanded] = useState(false)
  // const [searchName, setSearchName] = useState('')
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [selectedIndex, setSelectedIndex] = useState([])
  const [typeId, setTypeId] = useState()
  const [isLoading, setLoading] = useState(true)
  const [isFetchData, setFetchData] = useState(null)
  const [itemType, setItemType] = useState(null)
  // const [isOpen, setOpen] = useState(false)

  const url = process.env.REACT_APP_IMAGE_BASE_URL
  const data = props?.location?.state?.data
  let projectIDCrm = localStorage.getItem('project_id_crm')
  let decodedProjectId = base64Decode(projectIDCrm) // This is a Base64 encoded string

  let appraiserData = {
    type: 'appraiserList',
    project_id_crm: decodedProjectId,
  }

  const locationsData = {
    project_id_crm: decodedProjectId,
    type: 'locationData',
  }
  // const locationsDataWithAppraiser = {
  //   project_id_crm: decodedProjectId,
  //   type: 'locationDataWithAppraiser',
  // }

  const handleAccordionClick = (item) => {
    setTypeId(item?.type_id?.toString())
    setItemType(item?.type)
  }
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }


  useEffect(() => {
    let userId = id ?? 0
    setInputValues({appraiser: userId?.toString(), asset_id: data?.data?.rowData?.asset_id ?? data?.parentRowData?.asset_id})
    setExpanded(`panel${data?.data?.rowData?.type_id || data?.parentRowData?.type_id}`)
    setTypeId(data?.data?.rowData?.type_id?.toString() || data?.parentRowData?.type_id)
    setItemType(data?.data?.rowData?.type || data?.parentRowData?.type)
  }, [data])

  useEffect(() => {
    let assetId = data?.data?.rowData?.asset_id ?? data?.parentRowData?.asset_id
    let userId = data?.rowData?.user_id?.to ?? data?.inputValues?.appraiser
    let locationId = data?.data?.rowData?.location_id ?? data?.parentRowData?.location_id
    setLoading(true)
    let obj = {
      max: inputValues?.max ?? '',
      min: inputValues?.min ?? '',
      asset_id: assetId ?? null,
      location_id: locationId?.toString() ?? '',
      user_id: userId?.toString(),
      project_id_crm: decodedProjectId,
    }
    let imageData = {
      project_id_crm: decodedProjectId,
      user_id: id?.toString(),
    }
    let obj1 = {project_id_crm: decodedProjectId}
    try {
      Promise.all([
        fetchMakeCapacityList(appraiserData).then(({data}) => {
          setAppraisersList(data?.appraiserList)
        }),
        fetchLocations(locationsData).then(({data}) => {
          setLocationData(data.locationsDataFull)
        }),
        // fetchLocations(locationsDataWithAppraiser).then(({data}) => {
        //   setLocationData(data.locationsDataFull)
        // }),
        fetchAssetsByAppraiser(obj).then((res) => {
          const assetIds = res ? Object.keys(res) : ''
          if (assetIds?.length > 0) {
            // Get the first asset id
            const firstId = assetIds[0]
            let firstIndexData = res[+firstId]
            setExpanded(`panel${firstIndexData?.type_id}`)
            setTypeId(firstIndexData?.type_id?.toString())
            setItemType(firstIndexData?.type)

            // setInputValues({asset_id: firstId})
            // Set the first asset id in state
            setPhotosAssetData(res)
          } else {
            setPhotosAssetData([])
          }
        }),
        fetchPhotosByAppraiser(imageData).then((res) => {
          setImagesList(res)
        }),
        fetchAssetPhotos(obj1).then((res) => {
          setAssetsData(res)
        }),
      ]).then(() => setLoading(false))
    } catch (error) {
      console.log('error')
    }

  }, [])

  // useEffect(() => {
  //   fetchPhotosByAppraiser().then(() => {
  //     //
  //   }).catch(() => {
  //     //
  //   })
  // }, [inputValues?.appraiser])
  useEffect(() => {
    if (isFetchData) {
      setLoading(true)
      let obj1 = {project_id_crm: decodedProjectId}
      let imageData = {
        user_id: inputValues?.appraiser?.toString(),
        project_id_crm: decodedProjectId,
      }
      try {
        Promise.all([
          fetchAssetPhotos(obj1).then((res) => {
            setAssetsData(res)
          }),
          fetchPhotosByAppraiser(imageData).then((res) => {
            setImagesList(res)
          }),

        ]).then(() => {
          setLoading(false)
          setFetchData(false)
        })
      } catch (error) {
        console.log('error')
      }
    }
  }, [isFetchData])


  const handleChange = (e) => {
    if (e.target.name === 'min') {
      setInputValues({...inputValues, min: e.target.value, asset_id: null})
    }
    if (e.target.name === 'max') {
      setInputValues({...inputValues, max: e.target.value, asset_id: null})
    }
    if (e.target.name === 'asset_id') {
      setInputValues({...inputValues, asset_id: e.target.value, min: null, max: null})
    }
    if (e.target.name === 'appraiser') {
      setLoading(true)
      let obj = {
        asset_id: '',
        location_id: '',
        project_id_crm: decodedProjectId,
        user_id: e.target.value?.toString(),
      }
      let imageData = {
        user_id: e.target.value?.toString(),
        project_id_crm: decodedProjectId,
      }
      try {
        fetchAssetsByAppraiser(obj).then((res) => {
          const assetIds = res ? Object.keys(res) : ''
          if (assetIds?.length > 0) {

            // Get the first asset id
            const firstId = assetIds[0]
            let firstIndexData = res[+firstId]
            setExpanded(`panel${firstIndexData?.type_id}`)
            setTypeId(firstIndexData?.type_id?.toString())
            setItemType(firstIndexData?.type)
            // setInputValues({asset_id: firstId})
            // Set the first asset id in state
            setPhotosAssetData(res)
            setLoading(false)
          } else {
            setPhotosAssetData([])
            setLoading(false)
          }
        })
        fetchPhotosByAppraiser(imageData).then((res) => {
          setImagesList(res)
        })
      } catch (error) {
        console.log('error')
      }
      setInputValues({...inputValues, asset_id: null, max: null, min: null, location_id: null, appraiser: e.target.value?.toString()})
    }
    if (e.target.name === 'location_id') {
      setLoading(true)
      let obj = {
        max: inputValues?.max ?? '',
        min: inputValues?.min ?? '',
        asset_id: inputValues?.asset_id ?? '',
        location_id: e.target.value?.toString() === '0' ? '' : e.target.value?.toString(),
        user_id: inputValues?.appraiser.toString() ?? '',
        project_id_crm: decodedProjectId,
      }
      try {
        fetchAssetsByAppraiser(obj).then((res) => {
          const assetIds = res ? Object.keys(res) : ''
          if (assetIds.length > 0) {
            // Get the first asset id
            const firstId = assetIds[0]
            let firstIndexData = res[+firstId]
            setExpanded(`panel${firstIndexData?.type_id}`)
            setTypeId(firstIndexData?.type_id?.toString())
            setItemType(firstIndexData?.type)

            // setInputValues({asset_id: firstId})
            // Set the first asset id in state
            setPhotosAssetData(res)
            setLoading(false)
          } else {
            setPhotosAssetData([])
            setLoading(false)

          }
        })
      } catch (error) {
        console.log('error')
      }
      setInputValues({...inputValues, location_id: e.target.value})
    }
  }
  // const handleSearch = (e) => {
  //   const searchValue = e.target.value
  //   setSearchName(searchValue)
  //   if (searchValue !== '') {
  //     const result = appraisersList?.filter((item) => {
  //       return item?.name?.toLowerCase()?.startsWith(searchValue?.toLowerCase()) || item?.last_name?.toLowerCase().includes(searchValue.toLowerCase())
  //     })
  //     setAppraisersList(result)
  //   } else {
  //     setAppraisersList(appraisersList)
  //   }
  // }

  const handleListItemClick = (index) => {
    let imagesIndex = selectedIndex.find((item) => item === index)

    if (!imagesIndex) {
      setSelectedIndex([...selectedIndex, index])
    } else if (imagesIndex) {
      setSelectedIndex(selectedIndex?.filter((item) => item !== index))
    }
  }


  const handleSubmit = () => {
    let userId = data?.rowData?.user_id?.to ?? data?.inputValues?.appraiser
    let obj = {
      max: inputValues?.max ?? '',
      min: inputValues?.min ?? '',
      asset_id: inputValues?.asset_id ?? '',
      location_id: inputValues?.location_id?.toString() === '0' || inputValues?.location_id?.toString() === undefined ? '' : inputValues?.location_id?.toString(),
      user_id: inputValues?.appraiser ?? userId?.toString(),
      project_id_crm: decodedProjectId,
    }
    try {
      fetchAssetsByAppraiser(obj).then((res) => {
        const assetIds = res ? Object.keys(res) : ''
        if (assetIds.length > 0) {
          // Get the first asset id
          const firstId = assetIds[0]
          let firstIndexData = res[+firstId]
          setExpanded(`panel${firstIndexData?.type_id}`)
          setTypeId(firstIndexData?.type_id?.toString())
          setItemType(firstIndexData?.type)

          // setInputValues({asset_id: firstId})
          // Set the first asset id in state
          setPhotosAssetData(res)
        } else {
          setPhotosAssetData([])
        }
        // setLoading(false)
      })
    } catch (error) {
      console.log('error', error)
    }
  }
  const handleKeySubmit = (e) => {
    if (e.keyCode === 13) {
      handleSubmit()
    }
  }
  const handleReset = () => {
    setLoading(true)
    // let userId = data?.rowData?.user_id ?? data?.inputValues?.appraiser
    setInputValues({...inputValues, asset_id: null, max: null, min: null, location_id: null})
    let obj = {
      max: '',
      min: '',
      asset_id: '',
      location_id: '',
      user_id: inputValues?.appraiser,
      project_id_crm: decodedProjectId,
    }
    try {
      fetchAssetsByAppraiser(obj).then((res) => {
        const assetIds = res ? Object.keys(res) : ''
        if (assetIds.length > 0) {
          // Get the first asset id
          const firstId = assetIds[0]
          let firstIndexData = res[+firstId]
          setExpanded(`panel${firstIndexData?.type_id}`)
          setTypeId(firstIndexData?.type_id?.toString())
          setItemType(firstIndexData?.type)
          // setInputValues({asset_id: firstId})
          // Set the first asset id in state
          setPhotosAssetData(res)
          setLoading(false)
        } else {
          setPhotosAssetData([])
          setLoading(false)
        }
      })
    } catch (error) {
      console.log('error', error)
    }
    // setLoading(true)
    // handleSubmit()
    // handleSearch()
  }
  const handleAssign = () => {
    let obj = {
      images: selectedIndex?.join('~~~'),
      type: itemType,
      type_id: typeId?.toString(),
    }
    if (selectedIndex?.length === 0) {
      setMessage('Please choose at least one image.')
      setStatus('warning')
      return
    }
    if (typeId === undefined || expanded === false) {
      setMessage('Please choose Asset Id.')
      setStatus('warning')
    }

    if (typeId && selectedIndex?.length > 0 && expanded !== false) {
      try {
        setLoading(true)
        postPhotosToAssets(obj, {user_id: inputValues?.appraiser?.toString() ?? ''}).then((res) => {
          if (res.status === 200) {
            setMessage('Images Move Successfully')
            setStatus('success')
            setFetchData(true)
            setSelectedIndex([])
            // setTypeId(null)
          } else {
            setMessage('Something Went Wrong')
            setStatus('warning')
          }
        })
      } catch (error) {
        console.log('error')
      }
    }
  }
  const handleDelete = (id, object, item) => {
    setLoading(true)
    let obj = {
      photo_location: object?.path,
      type: item?.type,
      type_id: id?.toString(),
    }
    try {
      deletePhotosToAssets(obj, {user_id: inputValues?.appraiser?.toString() ?? ''}).then((res) => {
        setFetchData(true)
        setMessage('Image Delete Successfully')
        setStatus('success')
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
    dispatch(assetsListActions.clearMessageData())
  }
  const handleBackToEdit = () => {
    let data2 = data?.parentRowData
    let data3 = data?.data?.rowData
    let rowData = data3
    let parentRowData = data2
    let index = data?.data?.index ?? ''
    let rows = data?.data?.rows ?? ''
    if (data?.url?.includes('asset-detail')) {
      let key = {
        name: 'Edit Asset',
      }
      history.push({
        pathname: `/asset-detail/${data3?.type_id ?? data?.response?.id}`,
        state: {data: {rowData, index, rows, key}},
      })
    } else {
      let key = {
        name: 'Edit Line',
      }
      history.push({
        pathname: `/line-detail/${data2?.type_id}`,
        state: {data: {parentRowData, index, rows, key}},
      })
    }
  }

  const handleNominatePhoto = (id, obj, item) => {
    // setLoading(true)
    let payload = {
      type: item.type,
      op: 'nominate_status_update',
      type_id: id,
      photo_location: obj?.path,
      photo_nominated: obj?.photo_nominated === 1 ? '0' : '1',
      asset_media_type: 'photo',
    }
    try {
      postPhotosToAssets(payload).then(() => {
        console.log('image updated successful')
      })
      obj.photo_nominated = obj.photo_nominated === 0 ? 1 : 0

      // Update assetData.asset_photos object
      setAssetsData((prevData) => ({
        ...prevData,
        asset_photos: {
          ...prevData.asset_photos,
          [id]: prevData.asset_photos[id].map((photo) =>
            photo.path === obj.path ? {...photo, photo_nominated: obj.photo_nominated} : photo
          ),
        },
      }))
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    if (inputValues?.appraiser === '0') return // ! might end up bringing in new issues though
    if (appraisersList.length) {
      const appraiserIndex = appraisersList.findIndex(({user_id}) => +user_id === +inputValues?.appraiser)
      if (appraiserIndex === -1) {
        setInputValues({...inputValues, appraiser: appraisersList[0]?.user_id})
      }
    }
  }, [inputValues, appraisersList])
  // useEffect(() => {
  //   let expandedData = expanded ?? ''
  //   if (expandedData?.includes(typeId)) {
  //     setOpen(true)
  //   } else {
  //     setOpen(false)
  //   }

  // }, [expanded])
  return (
    <MyDiv>
      {(isLoading) &&
      <div>
        <Loader />
      </div>
      }
      <Box className="page_heading">
        <Grid className="map_photos">
          <Typography>Map Photos</Typography>
          {data &&
          <CustomButton title="Back to Edit Page"className="btn_theme" variant="contained" onClick={handleBackToEdit} />}
        </Grid>
      </Box>
      <Box className="admin_card">
        <Grid container columnSpacing={{md: 4}} className="action_grid">
          <Grid item md={2}>
            <Box className="input_box">
              <Typography>Select Appraiser</Typography>
              <FormControl size="small" fullWidth>
                <Select
                  id="select_input"
                  className="select_value"
                  value={+inputValues?.appraiser}
                  onChange={handleChange}
                  name="appraiser"
                  MenuProps={{...Menu, autoFocus: false}}
                >
                  {/* <CustomTextBox
                    onKeyDown={(e) => {
                      e.stopPropagation()
                    }}
                    autoFocus
                    value={searchName}
                    onChange={handleSearch}
                    className="search_box"
                    fieldLabel="Search"
                  /> */}
                  <StyledMenuItem value={0}>Please Select</StyledMenuItem>
                  {appraisersList?.map((item, index) => {
                    return (
                      <StyledMenuItem key={index} value={item.user_id}>
                        {item.last_name}{' '}{item.name}
                      </StyledMenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          {inputValues?.appraiser !== '0' && inputValues?.appraiser && (
            <Grid item md={2}>
              <Box className="input_box">
                <Typography>From</Typography>
                <CustomTextBox
                  onChange={handleChange}
                  value={inputValues?.min ?? ''}
                  name="min"
                  fieldLabel="Min. Asset Id"
                  type="number"
                  onKeyDown={handleKeySubmit}
                />
              </Box>
            </Grid>
          )}
          {inputValues?.appraiser !== '0' && inputValues?.appraiser && (
            <Grid item md={2}>
              <Box className="input_box">
                <Typography>To</Typography>
                <CustomTextBox
                  value={inputValues?.max ?? ''}
                  onChange={handleChange}
                  name="max"
                  type="number"
                  fieldLabel="Max. Asset Id"
                  onKeyDown={handleKeySubmit}
                />
              </Box>
            </Grid>
          )}
          {inputValues?.appraiser !== '0' && inputValues?.appraiser && (
            <Grid item md={2}>
              <Box className="input_box">
                <Typography>Go To</Typography>
                <CustomTextBox
                  onChange={handleChange}
                  value={inputValues?.asset_id ?? ''}
                  name="asset_id"
                  type="number"
                  fieldLabel="Asset Id"
                  onKeyDown={handleKeySubmit}
                />
              </Box>
            </Grid>
          )}
          {inputValues?.appraiser !== '0' && inputValues?.appraiser && (
            <Grid item md={2}>
              <Box className="input_box">
                <Typography>Select Location</Typography>
                <FormControl size="small" fullWidth>
                  <Select
                    id="select_input"
                    className="select_value"
                    value={inputValues?.location_id || 0}
                    onChange={handleChange}
                    name="location_id"
                    MenuProps={Menu}
                  >
                    <StyledMenuItem value={0}>Please Select</StyledMenuItem>
                    {locationData?.map((item, index) => {
                      return (
                        <StyledMenuItem key={index} value={item.location_id}>
                          {item.name} [{item.location_id}]
                        </StyledMenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          )}
          {inputValues?.appraiser !== '0' && inputValues?.appraiser && (
            <Grid item md={2}>
              <Box className="d-flex bottom_actions">
                <CustomButton title="Search" className="btn_theme" variant="contained" onClick={handleSubmit} />
                <CustomButton title="Reset" className="btn_reset" variant="contained" onClick={handleReset} />
              </Box>
            </Grid>
          )}
        </Grid>
        {inputValues?.appraiser !== '0' && inputValues?.appraiser && (
          <Box className="content_box">
            <Grid container columnSpacing={{md: 4}}>
              <Grid item md={6}>
                <ImagesList imagesList={imagesList} url={url} handleListItemClick={handleListItemClick} selectedIndex={selectedIndex}
                  handleAssign={handleAssign} assetsData={assetsData}
                />
              </Grid>
              <Grid item md={6}>
                <AssetIdList data={data} inputValues={inputValues} expanded={expanded} handleNominatePhoto={handleNominatePhoto} assetsData={assetsData} handleAccordionChange={handleAccordionChange} handleDelete={handleDelete}
                  url={url} handleAccordionClick={handleAccordionClick} photosAssetData={photosAssetData}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      <AlertMessage
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
    </MyDiv>
  )
}
