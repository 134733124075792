import {postAddAssets} from '../../../services/assetListService'
import {assetsListActions} from '../assets'

const postAddAssetAction = (payload, type = null, id = null) => {
  return (dispatch) => {
    dispatch(assetsListActions.postAddAssetRequest())
    postAddAssets(payload)
      .then((res) => {
        if (res.status === 200) {
          if (type === 'edit') {
            dispatch(
              assetsListActions.setMessageData({
                message: 'Asset Details Updated successfully.',
                status: 'success',
              })
            )
            let typeId = res.data.id // Default to response ID

            if (id === 'previousIdActive') {
              // const isTypeIdValid = typeof payload?.type_id === 'string' && payload.type_id.trim() !== ''
              const isTypeIdValid =
                (typeof payload?.type_id === 'string' &&
                  payload.type_id.trim() !== '') ||
                (typeof payload?.type_id === 'number' &&
                  !isNaN(payload.type_id))

              // Check if payload.copy_type_id is a non-empty string
              const isCopyTypeIdValid =
                (typeof payload?.copy_type_id === 'string' &&
                  payload.copy_type_id.trim() !== '') ||
                (typeof payload?.copy_type_id === 'number' &&
                  !isNaN(payload.copy_type_id))
              // const isCopyTypeIdValid = typeof payload?.copy_type_id === 'string' && payload.copy_type_id.trim() !== ''
              // typeId = isTypeIdValid
              //   ? payload.type_id
              //   : isCopyTypeIdValid
              //     ? payload.copy_type_id
              //     : res.data.id
              if (
                (isTypeIdValid && payload.type_id !== res.data.id) ||
              (isCopyTypeIdValid && payload.copy_type_id !== res.data.id)
              ) {
                typeId = res.data.id // Use res.data.id if either is valid but not equal to res.data.id
              } else {
              // Otherwise use the valid type_id or copy_type_id, or fallback to res.data.id
                typeId = isTypeIdValid
                  ? payload.type_id
                  : isCopyTypeIdValid
                    ? payload.copy_type_id
                    : res.data.id
              }
            }

            // Set the computed typeId in localStorage
            localStorage.setItem('type_id', typeId)
          } else {
            dispatch(
              assetsListActions.setMessageData({
                message: 'Asset Details Added successfully.',
                status: 'success',
              })
            )
            let typeId = res.data.id // Default to response ID

            if (id === 'previousIdActive') {
              const isTypeIdValid =
                (typeof payload?.type_id === 'string' &&
                  payload.type_id.trim() !== '') ||
                (typeof payload?.type_id === 'number' &&
                  !isNaN(payload.type_id))

              // Check if payload.copy_type_id is a non-empty string
              const isCopyTypeIdValid =
                (typeof payload?.copy_type_id === 'string' &&
                  payload.copy_type_id.trim() !== '') ||
                (typeof payload?.copy_type_id === 'number' &&
                  !isNaN(payload.copy_type_id))
              // typeId = isTypeIdValid
              //   ? payload.type_id
              //   : isCopyTypeIdValid
              //     ? payload.copy_type_id
              //     : res.data.id
              if (
                (isTypeIdValid && payload.type_id !== res.data.id) ||
              (isCopyTypeIdValid && payload.copy_type_id !== res.data.id)
              ) {
                typeId = res.data.id // Use res.data.id if either is valid but not equal to res.data.id
              } else {
              // Otherwise use the valid type_id or copy_type_id, or fallback to res.data.id
                typeId = isTypeIdValid
                  ? payload.type_id
                  : isCopyTypeIdValid
                    ? payload.copy_type_id
                    : res.data.id
              }
            }

            // Set the computed typeId in localStorage
            localStorage.setItem('type_id', typeId)
          }
        }
        dispatch(assetsListActions.postAddAssetSuccess(res))
        dispatch(assetsListActions.setDoFetchAssetList(true))
      })
      .catch((error) => {
        dispatch(
          assetsListActions.setMessageData({
            message: 'Something Went Wrong!',
            status: 'warning',
          })
        )
        dispatch(assetsListActions.postAddAssetFailure(error))
      })
  }
}

export default postAddAssetAction
