/* eslint-disable max-len */
import React from 'react'
import {
  Box,
  IconButton,
  ListItemButton,
  ListItemText,
  Tooltip,
} from '@mui/material'
import {FormatListBulleted as FormatListBulletedIcon, KeyboardDoubleArrowRight as KeyboardDoubleArrowRightIcon} from '@mui/icons-material'
import {FixedSizeList as WindowList} from 'react-window'
import MyDiv from './appraisersList.style'

export default function AppraisersList({selectedIndex, filteredAppraiserData, handlePopup, handleListItemClick, handleMoveAppraiserList}) {

  const RenderItem = ({index, style}) => (<Box
    style={style}
    className={
      selectedIndex.filter((data) => data === filteredAppraiserData[index]?.user_id)[0]
        ? 'add_item list_item'
        : 'list_item'
    }
  >
    <ListItemButton
      selected={selectedIndex === filteredAppraiserData[index]?.user_id}
      onClick={() => handleListItemClick(filteredAppraiserData[index]?.user_id)}
      key={filteredAppraiserData[index]?.user_id}
      className="list_btn"
    >
      <ListItemText
        primary={`${filteredAppraiserData[index]?.last_name === null ? '' : filteredAppraiserData[index]?.last_name}${' '}${filteredAppraiserData[index]?.first_name === null ? '' : filteredAppraiserData[index]?.first_name} (${filteredAppraiserData[index]?.user_id})`}
        className="label_name"
      />
    </ListItemButton>
    <Tooltip title="view assigned locations">
      <IconButton onClick={() => handlePopup(filteredAppraiserData[index]?.user_id, 'appraiserLocation')}>
        <FormatListBulletedIcon />
      </IconButton></Tooltip>
  </Box>)

  return (
    <MyDiv>
      <Box className="wrapper">
        <WindowList height={565} itemCount={filteredAppraiserData?.length} itemSize={35} width="100%">
          {RenderItem}
        </WindowList>
        <IconButton onClick={handleMoveAppraiserList} className="add_all">
          <KeyboardDoubleArrowRightIcon />
        </IconButton>
      </Box>
    </MyDiv>
  )
}
