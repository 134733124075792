/* eslint-disable no-empty-function */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState, useRef} from 'react'
import {Box, FormControl, Grid, Select, Typography, MenuItem} from '@mui/material'
import styled from 'styled-components'
import {useLocation, useHistory} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {decode as base64Decode} from 'base-64'
import {Typeahead} from 'react-bootstrap-typeahead'
import * as routesNames from '../../../constants/routes'
import {AlertMessage, CustomButton, Loader} from '../../../components'
import {importListActions} from '../../../redux/import/import'
import {importAsset, getImportedAssets} from '../../../redux/import/ImportActions'
import {importAssets} from '../../../services/importServices'
import {getAppraiserTrueAction} from '../../../redux/admin/AdminActions'
import MyDiv from './fieldMapping.style'

const StyledMenuItem = styled(MenuItem)(() => ({
  '&.MuiMenuItem-root': {
    borderBottom: '1px solid #ccc',
    color: '#000000',
    fontWeight: '400',
    fontSize: '14px',
    fontFamily: 'Arial',
  },
  '&.MuiMenuItem-root:first-child': {
    marginTop: '-8px',
  },
  '&.MuiMenuItem-root:last-child': {
    marginBottom: '-8px',
  },
}))

const ITEM_HEIGHT = 60
const Menu = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5,
    },
  },
}

const EXCEL_HEADERS = [
  {
    id: 1,
    name: 'Quantity',
    value: 'quantity',
  },
  {
    id: 2,
    name: 'Asset #',
    value: 'asset',
  },
  {
    id: 3,
    name: 'Make',
    value: 'make',
  },
  {
    id: 4,
    name: 'Model',
    value: 'model',
  },
  {
    id: 5,
    name: 'Capacity',
    value: 'capacity',
  },
  {
    id: 6,
    name: 'Asset Type',
    value: 'asset_type',
  },
  {
    id: 7,
    name: 'Serial Number',
    value: 'serial_number',
  },
  {
    id: 8,
    name: 'Year',
    value: 'year',
  },
  {
    id: 9,
    name: 'Description',
    value: 'description',
  },
  {
    id: 10,
    name: 'Condition',
    value: 'condition',
  },
  {
    id: 11,
    name: 'Hour/Mileage',
    value: 'hour',
  },
  {
    id: 12,
    name: 'New Cost',
    value: 'new_cost',
  },
  {
    id: 13,
    name: 'FLV',
    value: 'flv',
  },
  {
    id: 14,
    name: 'OLV',
    value: 'olv',
  },
  {
    id: 15,
    name: 'OLVIP',
    value: 'olvip',
  },
  {
    id: 16,
    name: 'FMV',
    value: 'fmv',
  },
  {
    id: 17,
    name: 'FMVIP',
    value: 'fmvip',
  },
  {
    id: 18,
    name: 'FV',
    value: 'fv',
  },
  {
    id: 19,
    name: 'RCN',
    value: 'rcn',
  },
  {
    id: 20,
    name: 'CV',
    value: 'cv',
  },
  {
    id: 21,
    name: 'Area',
    value: 'area',
  },
]
const separatorData = [
  {
    id: 1,
    value: '&&',
  },
  {
    id: 2,
    value: '||',
  },
  {
    id: 3,
    value: '$$',
  },
]

export default function FieldMapping() {

  const history = useHistory()
  const location = useLocation()
  const skipRowsData = location?.state?.data
  const dispatch = useDispatch()
  const importState = useSelector((state) => state.import)
  const [inputValues, setInputValues] = useState({})
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [isSubmit, setSubmit] = useState(false)
  const importData = importState?.uploadFile?.uploadFile?.data

  let projectIDCrm = localStorage.getItem('project_id_crm')
  let decodedProjectId = base64Decode(projectIDCrm) // This is a Base64 encoded string
  let redirectUrl = importState?.postImportAssets?.postImportAssets?.data?.redirect_url


  const quantityRef = useRef()
  const assetRef = useRef()
  const makeRef = useRef()
  const modelRef = useRef()
  const capacityRef = useRef()
  const assetTypeRef = useRef()
  const serialNumberRef = useRef()
  const yearRef = useRef()
  const descriptionRef = useRef()
  const conditionRef = useRef()
  const hourRef = useRef()
  const newCostRef = useRef()
  const FLVRef = useRef()
  const OLVRef = useRef()
  const OLVIPRef = useRef()
  const FMVRef = useRef()
  const FMVIPRef = useRef()
  const FVRef = useRef()
  const RCNRef = useRef()
  const CVRef = useRef()
  const areaRef = useRef()


  useEffect(() => {
    if (!importState?.uploadFile?.uploadFile?.data) {
      history.goBack()
    }
  }, [importState?.uploadFile])

  const obj = {
    action: 'fetch_project_imported_asset_count',
    project_id_crm: decodedProjectId,
  }
  const appraiserTrue = {
    getAppraisersOnly: true,
    project_id_crm: decodedProjectId,
  }
  useEffect(() => {
    if (isSubmit) {
      dispatch(getImportedAssets(obj))
      dispatch(getAppraiserTrueAction(appraiserTrue))
    }
  }, [isSubmit])

  useEffect(() => {
    if (importState?.postImportAssets?.postImportAssets?.data?.code === 1) {
      window.location.href = redirectUrl
    }
  }, [importState?.postImportAssets?.postImportAssets])

  const handleChange = (e) => {
    setInputValues({...inputValues, [e.target.name]: e.target.value})
  }
  useEffect(() => {
    setInputValues({...inputValues, separator: '||'})
  }, [])

  const handleSubmit = () => {
    let arr = []
    EXCEL_HEADERS?.map((item) => {
      let name = item.name
      for (let i in inputValues) {
        if (i === item.value && inputValues[item.value].length) {
          let obj = {
            [name]: inputValues[item.value]?.join(',##,'),
          }
          arr.push(obj)
        }
      }
    })
    const transformedData = arr.reduce((acc, obj) => {
      const key = Object.keys(obj)[0]
      acc[key] = obj[key]
      return acc
    }, {})
    if (!(inputValues?.make && inputValues?.asset_type) || !inputValues?.make?.length || !inputValues?.asset_type?.length) {
      setMessage('Please Map Make and Asset Type Fields')
      setStatus('warning')
      return
    }

    let data = {
      mapHeaders: transformedData,
      fileName: importData?.fileName,
      importSeparator: inputValues?.separator ?? '',
      importData: 1,
      importProjectId: decodedProjectId,
      skipRows: skipRowsData,
    }
    // importAssets(data).then(({data}) => {
    //   if (data?.code === 1) {
    //     window.location.href = data.redirect_url
    //   }
    // }).catch((error) => {
    // })
    dispatch(importAsset(data))
    setSubmit(true)

  }

  const handleBack = () => {
    // history.push(routesNames.IMPORTASSET)
    window.location.reload()
  }

  const handleReset = () => {
    quantityRef.current.clear()
    assetRef.current.clear()
    makeRef.current.clear()
    modelRef.current.clear()
    capacityRef.current.clear()
    assetTypeRef.current.clear()
    serialNumberRef.current.clear()
    yearRef.current.clear()
    descriptionRef.current.clear()
    conditionRef.current.clear()
    hourRef.current.clear()
    newCostRef.current.clear()
    FLVRef.current.clear()
    OLVRef.current.clear()
    FMVRef.current.clear()
    OLVIPRef.current.clear()
    FMVIPRef.current.clear()
    FVRef.current.clear()
    RCNRef.current.clear()
    CVRef.current.clear()
    areaRef.current.clear()


    setInputValues({separator: inputValues.separator})
  }

  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
    dispatch(importListActions.clearMessageData())
  }
  const handleSelected = (selected, type) => {
    switch (type) {
      // if we have a label, we set the values accordingly based on a ternary check
      case 'quantity':
        setInputValues({...inputValues, selectedQuantity: selected, quantity: selected?.map((item) => {return item})})
        break
      case 'asset':
        setInputValues({...inputValues, selectedAsset: selected, asset: selected?.map((item) => {return item})})
        break
      case 'make':
        setInputValues({...inputValues, selectedMake: selected, make: selected?.map((item) => {return item})})
        break
      case 'model':
        setInputValues({...inputValues, selectedModel: selected, model: selected?.map((item) => {return item})})
        break
      case 'capacity':
        setInputValues({...inputValues, selectedCapacity: selected, capacity: selected?.map((item) => {return item})})
        break
      case 'asset_type':
        setInputValues({...inputValues, selectedAssetType: selected, asset_type: selected?.map((item) => {return item})})
        break
      case 'serial_number':
        setInputValues({...inputValues, selectedSerialNumber: selected, serial_number: selected?.map((item) => {return item})})
        break
      case 'year':
        setInputValues({...inputValues, selectedYear: selected, year: selected?.map((item) => {return item})})
        break
      case 'description':
        setInputValues({...inputValues, selectedDescription: selected, description: selected?.map((item) => {return item})})
        break
      case 'condition':
        setInputValues({...inputValues, selectedCondition: selected, condition: selected?.map((item) => {return item})})
        break
      case 'hour':
        setInputValues({...inputValues, selectedHour: selected, hour: selected?.map((item) => {return item})})
        break
      case 'new_cost':
        setInputValues({...inputValues, selectedNewCost: selected, new_cost: selected?.map((item) => {return item})})
        break
      case 'flv':
        setInputValues({...inputValues, selectedFLV: selected, flv: selected?.map((item) => {return item})})
        break
      case 'olv':
        setInputValues({...inputValues, selectedOLV: selected, olv: selected?.map((item) => {return item})})
        break
      case 'olvip':
        setInputValues({...inputValues, selectedOLVIP: selected, olvip: selected?.map((item) => {return item})})
        break
      case 'fmv':
        setInputValues({...inputValues, selectedFMV: selected, fmv: selected?.map((item) => {return item})})
        break
      case 'fmvip':
        setInputValues({...inputValues, selectedFMVIP: selected, fmvip: selected?.map((item) => {return item})})
        break
      case 'fv':
        setInputValues({...inputValues, selectedFV: selected, fv: selected?.map((item) => {return item})})
        break
      case 'rcn':
        setInputValues({...inputValues, selectedRCN: selected, rcn: selected?.map((item) => {return item})})
        break
      case 'cv':
        setInputValues({...inputValues, selectedCV: selected, cv: selected?.map((item) => {return item})})
        break
      case 'area':
        setInputValues({...inputValues, selectedArea: selected, area: selected?.map((item) => {return item})})
        break
      default:
        break
    }
  }

  return (
    <MyDiv>
      {(importState?.postImportAssets?.loading) &&
      <div>
        <Loader />
      </div>
      }
      <Box className="page_heading">
        <Typography>Field Mapping</Typography>
      </Box>
      <Typography className="total">Total Records: {importData?.totalRows}</Typography>
      <Box className="import_form">
        <Box className="input_box">
          <Typography>Set Separator</Typography>
          <FormControl size="small" fullWidth>
            <Select
              id="select_input"
              className="select_value"
              value={inputValues.separator ?? ''}
              onChange={handleChange}
              name="separator"
              MenuProps={{...Menu}}
            >
              {separatorData?.map((item, index) => {
                return (
                  <StyledMenuItem key={index} value={item.value}>
                    {item.value}
                  </StyledMenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Box>
        <Box className="input_box">
          <Typography>Quantity</Typography>
          <FormControl size="small" fullWidth>
            <Grid item md={3}>
              <Box className="input_box">
                <Typeahead
                  id="basic-typeahead"
                  clearButton
                  labelKey={(option) => option || ''}
                  options={importData?.headers}
                  placeholder="Type or Click here"
                  ref={quantityRef}

                  // disabled={data?.sleekMode}
                  selected={inputValues?.selectedQuantity} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  onChange={(selected) => handleSelected(selected, 'quantity')}
                />
              </Box>
            </Grid>
          </FormControl>
        </Box>
        <Box className="input_box">
          <Typography>Asset #</Typography>
          <FormControl size="small" fullWidth>
            <Grid item md={3}>
              <Box className="input_box">
                <Typeahead
                  id="basic-typeahead"
                  clearButton
                  labelKey={(option) => option || ''}
                  options={importData?.headers}
                  placeholder="Type or Click here"
                  selected={inputValues?.selectedAsset} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  ref={assetRef}
                  onChange={(selected) => handleSelected(selected, 'asset')}
                />
              </Box>
            </Grid>
          </FormControl>
        </Box>
        <Box className="input_box">
          <Typography>Make <span style={{color: 'red'}}>*</span></Typography>
          <FormControl size="small" fullWidth>
            <Grid item md={3}>
              <Box className="input_box">
                <Typeahead
                  id="basic-typeahead"
                  clearButton
                  labelKey={(option) => option || ''}
                  // filterBy={(option, props) => {
                  //   return option?.make?.toLowerCase().startsWith(props?.text.toLowerCase())
                  // }}
                  options={importData?.headers}
                  placeholder="Type or Click here"
                  selected={inputValues?.selectedMake} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  ref={makeRef}
                  onChange={(selected) => handleSelected(selected, 'make')}
                />
              </Box>
            </Grid>
          </FormControl>
        </Box>
        <Box className="input_box">
          <Typography>Model</Typography>
          <FormControl size="small" fullWidth>
            <Grid item md={3}>
              <Box className="input_box">
                <Typeahead
                  id="basic-typeahead"
                  clearButton
                  labelKey={(option) => option || ''}
                  options={importData?.headers}
                  placeholder="Type or Click here"
                  selected={inputValues?.selectedModel} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  ref={modelRef}
                  onChange={(selected) => handleSelected(selected, 'model')}
                />
              </Box>
            </Grid>
          </FormControl>
        </Box>
        <Box className="input_box">
          <Typography>Capacity</Typography>
          <FormControl size="small" fullWidth>
            <Grid item md={3}>
              <Box className="input_box">
                <Typeahead
                  id="basic-typeahead"
                  clearButton
                  labelKey={(option) => option || ''}
                  options={importData?.headers}
                  placeholder="Type or Click here"
                  selected={inputValues?.selectedCapacity} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  ref={capacityRef}
                  onChange={(selected) => handleSelected(selected, 'capacity')}
                />
              </Box>
            </Grid>
          </FormControl>
        </Box>
        <Box className="input_box">
          <Typography>Asset Type <span style={{color: 'red'}}>*</span></Typography>
          <FormControl size="small" fullWidth>
            <Grid item md={3}>
              <Box className="input_box">
                <Typeahead
                  id="basic-typeahead"
                  clearButton
                  // filterBy={(option, props) => {
                  //   return option?.asset_type?.toLowerCase().startsWith(props?.text.toLowerCase())
                  // }}
                  labelKey={(option) => option || ''}
                  options={importData?.headers}
                  placeholder="Type or Click here"
                  selected={inputValues?.selectedAssetType} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  ref={assetTypeRef}
                  onChange={(selected) => handleSelected(selected, 'asset_type')}
                />
              </Box>
            </Grid>
          </FormControl>
        </Box>
        <Box className="input_box">
          <Typography>Serial Number</Typography>
          <FormControl size="small" fullWidth>
            <Grid item md={3}>
              <Box className="input_box">
                <Typeahead
                  id="basic-typeahead"
                  clearButton
                  labelKey={(option) => option || ''}
                  options={importData?.headers}
                  placeholder="Type or Click here"
                  selected={inputValues?.selectedSerialNumber} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  ref={serialNumberRef}
                  onChange={(selected) => handleSelected(selected, 'serial_number')}
                />
              </Box>
            </Grid>
          </FormControl>
        </Box>
        <Box className="input_box">
          <Typography>Year</Typography>
          <FormControl size="small" fullWidth>
            <Grid item md={3}>
              <Box className="input_box">
                <Typeahead
                  id="basic-typeahead"
                  clearButton
                  labelKey={(option) => option || ''}
                  options={importData?.headers}
                  placeholder="Type or Click here"
                  selected={inputValues?.selectedYear} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  ref={yearRef}
                  onChange={(selected) => handleSelected(selected, 'year')}
                />
              </Box>
            </Grid>
          </FormControl>
        </Box>
        <Box className="input_box">
          <Typography>Description</Typography>
          <FormControl size="small" fullWidth>
            <Grid item md={3}>
              <Box className="input_box">
                <Typeahead
                  id="basic-typeahead"
                  clearButton
                  labelKey={(option) => option || ''}
                  options={importData?.headers}
                  placeholder="Type or Click here"
                  selected={inputValues?.selectedDescription} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  ref={descriptionRef}
                  onChange={(selected) => handleSelected(selected, 'description')}
                />
              </Box>
            </Grid>
          </FormControl>
        </Box>
        <Box className="input_box">
          <Typography>Condition</Typography>
          <FormControl size="small" fullWidth>
            <Grid item md={3}>
              <Box className="input_box">
                <Typeahead
                  id="basic-typeahead"
                  clearButton
                  labelKey={(option) => option || ''}
                  options={importData?.headers}
                  placeholder="Type or Click here"
                  selected={inputValues?.selectedCondition} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  ref={conditionRef}
                  onChange={(selected) => handleSelected(selected, 'condition')}
                />
              </Box>
            </Grid>
          </FormControl>
        </Box>
        <Box className="input_box">
          <Typography>Hour/Mileage</Typography>
          <FormControl size="small" fullWidth>
            <Grid item md={3}>
              <Box className="input_box">
                <Typeahead
                  id="basic-typeahead"
                  clearButton
                  labelKey={(option) => option || ''}
                  options={importData?.headers}
                  placeholder="Type or Click here"
                  selected={inputValues?.selectedHour} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  ref={hourRef}
                  onChange={(selected) => handleSelected(selected, 'hour')}
                />
              </Box>
            </Grid>
          </FormControl>
        </Box>
        <Box className="input_box">
          <Typography>New Cost</Typography>
          <FormControl size="small" fullWidth>
            <Grid item md={3}>
              <Box className="input_box">
                <Typeahead
                  id="basic-typeahead"
                  clearButton
                  labelKey={(option) => option || ''}
                  options={importData?.headers}
                  placeholder="Type or Click here"
                  selected={inputValues?.selectedNewCost} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  ref={newCostRef}
                  onChange={(selected) => handleSelected(selected, 'new_cost')}
                />
              </Box>
            </Grid>
          </FormControl>
        </Box>
        <Box className="input_box">
          <Typography>FLV</Typography>
          <FormControl size="small" fullWidth>
            <Grid item md={3}>
              <Box className="input_box">
                <Typeahead
                  id="basic-typeahead"
                  clearButton
                  labelKey={(option) => option || ''}
                  options={importData?.headers}
                  placeholder="Type or Click here"
                  selected={inputValues?.selectedFLV} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  ref={FLVRef}
                  onChange={(selected) => handleSelected(selected, 'flv')}
                />
              </Box>
            </Grid>
          </FormControl>
        </Box>
        <Box className="input_box">
          <Typography>OLV</Typography>
          <FormControl size="small" fullWidth>
            <Grid item md={3}>
              <Box className="input_box">
                <Typeahead
                  id="basic-typeahead"
                  clearButton
                  labelKey={(option) => option || ''}
                  options={importData?.headers}
                  placeholder="Type or Click here"
                  selected={inputValues?.selectedOLV} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  ref={OLVRef}
                  onChange={(selected) => handleSelected(selected, 'olv')}
                />
              </Box>
            </Grid>
          </FormControl>
        </Box>
        <Box className="input_box">
          <Typography>OLVIP</Typography>
          <FormControl size="small" fullWidth>
            <Grid item md={3}>
              <Box className="input_box">
                <Typeahead
                  id="basic-typeahead"
                  clearButton
                  labelKey={(option) => option || ''}
                  options={importData?.headers}
                  placeholder="Type or Click here"
                  selected={inputValues?.selectedOLVIP} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  ref={OLVIPRef}
                  onChange={(selected) => handleSelected(selected, 'olvip')}
                />
              </Box>
            </Grid>
          </FormControl>
        </Box>
        <Box className="input_box">
          <Typography>FMV</Typography>
          <FormControl size="small" fullWidth>
            <Grid item md={3}>
              <Box className="input_box">
                <Typeahead
                  id="basic-typeahead"
                  clearButton
                  labelKey={(option) => option || ''}
                  options={importData?.headers}
                  placeholder="Type or Click here"
                  selected={inputValues?.selectedFMV} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  ref={FMVRef}
                  onChange={(selected) => handleSelected(selected, 'fmv')}
                />
              </Box>
            </Grid>
          </FormControl>
        </Box>
        <Box className="input_box">
          <Typography>FMVIP</Typography>
          <FormControl size="small" fullWidth>
            <Grid item md={3}>
              <Box className="input_box">
                <Typeahead
                  id="basic-typeahead"
                  clearButton
                  labelKey={(option) => option || ''}
                  options={importData?.headers}
                  placeholder="Type or Click here"
                  selected={inputValues?.selectedFMVIP} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  ref={FMVIPRef}
                  onChange={(selected) => handleSelected(selected, 'fmvip')}
                />
              </Box>
            </Grid>
          </FormControl>
        </Box>
        <Box className="input_box">
          <Typography>FV</Typography>
          <FormControl size="small" fullWidth>
            <Grid item md={3}>
              <Box className="input_box">
                <Typeahead
                  id="basic-typeahead"
                  clearButton
                  labelKey={(option) => option || ''}
                  options={importData?.headers}
                  placeholder="Type or Click here"
                  selected={inputValues?.selectedFV} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  ref={FVRef}
                  onChange={(selected) => handleSelected(selected, 'fv')}
                />
              </Box>
            </Grid>
          </FormControl>
        </Box>
        <Box className="input_box">
          <Typography>RCN</Typography>
          <FormControl size="small" fullWidth>
            <Grid item md={3}>
              <Box className="input_box">
                <Typeahead
                  id="basic-typeahead"
                  clearButton
                  labelKey={(option) => option || ''}
                  options={importData?.headers}
                  placeholder="Type or Click here"
                  selected={inputValues?.selectedRCN} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  ref={RCNRef}
                  onChange={(selected) => handleSelected(selected, 'rcn')}
                />
              </Box>
            </Grid>
          </FormControl>
        </Box>
        <Box className="input_box">
          <Typography>CV</Typography>
          <FormControl size="small" fullWidth>
            <Grid item md={3}>
              <Box className="input_box">
                <Typeahead
                  id="basic-typeahead"
                  clearButton
                  labelKey={(option) => option || ''}
                  options={importData?.headers}
                  placeholder="Type or Click here"
                  selected={inputValues?.selectedCV} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  ref={CVRef}
                  onChange={(selected) => handleSelected(selected, 'cv')}
                />
              </Box>
            </Grid>
          </FormControl>
        </Box>
        <Box className="input_box">
          <Typography>Area</Typography>
          <FormControl size="small" fullWidth>
            <Grid item md={3}>
              <Box className="input_box">
                <Typeahead
                  id="basic-typeahead"
                  clearButton
                  labelKey={(option) => option || ''}
                  options={importData?.headers}
                  placeholder="Type or Click here"
                  selected={inputValues?.selectedArea} // we are expecting it in the format [{key:key_value, value: value}]
                  size="large"
                  ref={areaRef}
                  onChange={(selected) => handleSelected(selected, 'area')}
                />
              </Box>
            </Grid>
          </FormControl>
        </Box>
      </Box>
      <Box className="d-flex btn-flex mb-20 mt-10">
        <CustomButton
          title="Submit"
          variant="contained"
          className="btn_theme"
          onClick={handleSubmit}
        />
        <CustomButton
          title="Reset"
          variant="contained"
          className="btn_theme"
          onClick={handleReset}
        />
        <CustomButton
          title="Back"
          variant="contained"
          className="btn_theme"
          onClick={handleBack}
        />
      </Box>
      <AlertMessage
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
    </MyDiv>
  )
}
